import React, { useEffect, useState } from 'react';

const images = [
    'hello',
    'axeman',
    'archer',
    'cavalry-archer',
    'crossbow',
    'heavy-cavalry',
    'light-cavalry',
    'longbow',
    'maceman',
    'pikeman',
    'scout',
    'swordsman',
];

export const UnitImage = ({ unitName }) => {
    return (
        <div
            className="container"
            style={{
                backgroundImage: `url('/img/units/${unitName}.svg')`,
                backgroundRepeat: 'no-repeat',
                height: '120px',
            }}
        ></div>
    );
};

export const ChangingUnitImage = () => {
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setImageIndex((imageIndex + 1) % images.length);
        }, 15 * 1000);

        return () => clearTimeout(timer);
    }, [imageIndex]);

    return <UnitImage unitName={images[imageIndex]} />;
};

export default UnitImage;
