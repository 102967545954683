import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import UnitImage from '../components/UnitImage';

export const Head = ({ data }) => {
    const post = data.markdownRemark;

    return (
        <title>
            {post.frontmatter.title} - {data.site.siteMetadata.title}
        </title>
    );
};

const BlogPost = ({ data }) => {
    const post = data.markdownRemark;

    console.log(post.frontmatter);

    return (
        <Layout>
            {post.frontmatter.unitName ? <UnitImage unitName={post.frontmatter.unitName} /> : null}
            <article className="box">
                <h1 className="title is-1">{post.frontmatter.title}</h1>
                <p className="my-3">
                    <small className="tag is-info is-light">
                        <span className="icon">
                            <i className="far fa-calendar-alt"></i>
                        </span>
                        {post.frontmatter.date}
                    </small>
                </p>
                <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </article>
        </Layout>
    );
};

export default BlogPost;

export const query = graphql`
    query ($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date(formatString: "DD MMMM YYYY")
                unitName
            }
            fields {
                slug
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`;
